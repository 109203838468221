:root {
  --primary-color: #4a0e4e; /* Deep purple */
  --secondary-color: #4d0f94; /* Lighter purple */
  --accent-color: #3498db; /* Blue accent */
  --background-color: #ffffff; /* White background */
  --text-color: #333333; /* Dark gray for main text */
  --light-text-color: #666666; /* Medium gray for subtitles */
  --error-color: #e74c3c; /* Red for errors */
  --success-color: #2ecc71; /* Green for success */
  --button-color: #b8860b; /* Dark Golden Rod */
}

body {
  background-color: var(--background-color);
  color: var(--text-color);
  font-family: 'Inter', 'Segoe UI', 'Roboto', sans-serif;
  line-height: 1.6;
  margin: 0;
  padding: 0;
}

.app-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 40px 20px;
}

.app-header {
  text-align: center;
  margin-bottom: 40px;
}

.app-header h1 {
  font-size: 3.5em;
  font-weight: 800;
  color: var(--primary-color);
  margin: 0;
  text-shadow: 2px 2px 4px rgba(0,0,0,0.1);
}

.app-header .subtitle {
  font-size: 1.2em;
  font-weight: 400;
  color: var(--light-text-color);
  margin: 10px 0 0;
}

.app-main {
  background: #f8f9fa;
  border-radius: 8px;
  padding: 30px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  position: relative;
  min-height: 400px;
  transition: min-height 0.3s ease;
}

.app-main.loading {
  min-height: 600px;
}

.upload-section, .url-section, .result-section {
  margin-bottom: 30px;
}

h2 {
  color: var(--primary-color);
  margin-bottom: 20px;
  font-size: 1.8em;
  font-weight: 600;
}

.form-group {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.file-input-wrapper {
  position: relative;
  overflow: hidden;
  display: inline-block;
}

.file-input {
  position: absolute;
  font-size: 100px;
  right: 0;
  top: 0;
  opacity: 0;
  cursor: pointer;
}

.file-label {
  display: inline-block;
  padding: 10px 20px;
  background-color: var(--button-color);
  color: #ffffff;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.1s;
  font-weight: 500;
}

.file-label:hover {
  background-color: #a67c00;
  transform: translateY(-1px);
}

.file-label:active {
  transform: translateY(1px);
}

.url-input {
  width: 100%;
  padding: 10px;
  border: 1px solid var(--light-text-color);
  border-radius: 4px;
  font-size: 16px;
  color: var(--text-color);
  transition: border-color 0.3s;
}

.url-input:focus {
  outline: none;
  border-color: var(--accent-color);
}

.submit-button, .generate-another-button {
  padding: 10px 20px;
  background-color: var(--button-color);
  color: #ffffff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.1s;
  font-size: 16px;
  font-weight: 500;
}

.submit-button:hover, .generate-another-button:hover {
  background-color: #a67c00;
  transform: translateY(-1px);
}

.submit-button:active, .generate-another-button:active {
  transform: translateY(1px);
}

.submit-button:disabled {
  background-color: #bdc3c7;
  color: #7f8c8d;
  cursor: not-allowed;
  transform: none;
}

.loading-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.9);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.loading-content {
  text-align: center;
}

.loading-spinner {
  border: 4px solid #f3f3f3;
  border-top: 4px solid var(--button-color);
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite;
  margin: 20px auto;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.audio-player {
  width: 100%;
  margin-bottom: 20px;
}

.download-link {
  display: inline-block;
  padding: 10px 20px;
  background-color: var(--button-color);
  color: #ffffff;
  text-decoration: none;
  border-radius: 4px;
  transition: background-color 0.3s, transform 0.1s;
  cursor: pointer;
  font-weight: 500;
  margin-right: 10px;
}

.download-link:hover {
  background-color: #a67c00;
  transform: translateY(-1px);
}

.download-link:active {
  transform: translateY(1px);
}

.generate-another-button {
  margin-top: 20px;
}

.file-info {
  display: flex;
  align-items: center;
  background-color: #f1f3f5;
  padding: 8px 12px;
  border-radius: 4px;
  margin-top: 10px;
}

.file-info span {
  margin-right: 10px;
  word-break: break-all;
  flex-grow: 1;
  color: var(--text-color);
}

.delete-file {
  background: none;
  border: none;
  cursor: pointer;
  color: var(--error-color);
  padding: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  transition: background-color 0.3s;
}

.delete-file:hover {
  background-color: #fdeaea;
}

/* Responsive adjustments */
@media (max-width: 600px) {
  .app-container {
    padding: 20px 10px;
  }

  .app-main {
    padding: 20px;
  }

  .app-header h1 {
    font-size: 2.5em;
  }

  .app-header .subtitle {
    font-size: 1em;
  }
}

.keys-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: var(--button-color);
  color: white;
  border: none;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: background-color 0.3s;
}

.keys-button:hover {
  background-color: #a67c00;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  width: 300px;
}

.modal-content h2 {
  margin-top: 0;
}

.modal-content label {
  display: block;
  margin-top: 10px;
}

.modal-content input {
  width: 100%;
  padding: 5px;
  margin-top: 5px;
}

.modal-buttons {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}

.modal-buttons button {
  margin-left: 10px;
  padding: 5px 10px;
  background-color: var(--button-color);
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.modal-buttons button:hover {
  background-color: #a67c00;
}

.process-stage {
  display: flex;
  align-items: center;
  margin: 15px 0;
}

.icon-wrapper {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #f0f0f0;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 15px;
}

.icon-wrapper.active {
  background-color: #4CAF50;
  animation: pulse 1.5s infinite;
}

.icon-wrapper svg {
  width: 24px;
  height: 24px;
}

.active svg {
  stroke: white;
}

@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 rgba(76, 175, 80, 0.4);
  }
  70% {
    box-shadow: 0 0 0 10px rgba(76, 175, 80, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(76, 175, 80, 0);
  }
}

